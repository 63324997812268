<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}: </span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model.trim="userGroupNom"
                label="Nombre del grupo"
                dense
                outlined
                auto-grow
                rows="1"></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
export default {
  name: "EditGrupo",
  props: {
    GrupoObj: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rules: rules,
      formEditTitle: "Editar grupo",
      newTitle: "Nuevo grupo",
      userGroupNom: "",
      maxWidth: "80%",
      isFormValid: false,
    };
  },
  created() {
    if (this.GrupoObj) this.setGrupo();
    else this.newAgrupacion();
  },
  methods: {
    ...mapActions({
      saveGrupoUsuario: "usuarios/saveGrupoUsuario",
      deleteUsuario: "usuarios/deleteUsuario",
      setAlert: "user/setAlert",
    }),
    setGrupo() {
      this.userGroupNom = this.GrupoObj.userGroupNom;
    },
    newAgrupacion() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      this.isFormValid = false;
      let data = {
        userGroupId: this.GrupoObj ? this.GrupoObj.userGroupId : 0,
        userGroupNom: this.userGroupNom,
      };
      const res = await this.saveGrupoUsuario(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>
