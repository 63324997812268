<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="1" style="padding-top: 19px">
        <go-back-btn :routeToGo="routeToGo" />
      </v-col>
      <v-col cols="11">
        <PageHeader :title="title" class="mb-3" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10">
        <v-select
          outlined
          v-model="userGroupId"
          :items="grupos"
          item-text="userGroupNom"
          item-value="userGroupId"
          label="Grupo"
          @change="onGrupoChange(userGroupId)">
        </v-select>
      </v-col>
      <v-col cols="2" align="end">
        <v-btn
          color="primary"
          @click="openModalGrupos"
          :loading="btnIsLoading"
          class="mt-2"
          >Grupos</v-btn
        >
      </v-col>
    </v-row>
    <div class="text-center mt-4" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate></v-progress-circular>
    </div>
    <v-container class="pa-0">
      <v-treeview
        hoverable
        selection-type="independent"
        selected-color="primary"
        item-disabled="locked"
        :items="itemsPermisos"
        v-model="selectedItems"
        ref="tree">
        <template v-slot:prepend="{ item }">
          <div style="display: flex; align-items: center">
            <v-checkbox
              v-model="item.selected"
              color="primary"
              hide-details
              class="pt-0 mt-0"
              @change="onItemChecked(item)">
            </v-checkbox>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.optionType == 1"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on">
                  mdi-alpha-m-box
                </v-icon>
                <v-icon
                  v-if="item.optionType == 2"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on">
                  mdi-alpha-o-box
                </v-icon>
                <v-icon
                  v-if="item.optionType == 3"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on">
                  mdi-alpha-p-box
                </v-icon>
              </template>
              <span v-if="item.optionType == 1">Opción de menú</span>
              <span v-if="item.optionType == 2">Opción de pantalla</span>
              <span v-if="item.optionType == 3">Permiso de usuario</span>
            </v-tooltip>

            <span>{{ item.title }}</span>
          </div>
          <!-- Agregar casillas de verificación -->
        </template>
      </v-treeview>
    </v-container>
    <v-row>
      <v-col cols="12" align="right">
        <v-btn
          color="primary"
          @click="save"
          :loading="btnIsLoading"
          :disabled="itemsPermisos.length == 0"
          >Guardar</v-btn
        >
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEditGrupos"
      v-model="openModalEditGrupos"
      max-width="80%"
      @keydown.esc="closeModalGrupos"
      persistent>
      <VerGrupos @closeModalAgrupacion="closeModalGrupos"></VerGrupos>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import PageHeader from "@/components/ui/PageHeader.vue";
import VerGrupos from "./VerGrupos.vue";

export default {
  name: "Permisos",
  components: {
    GoBackBtn,
    PageHeader,
    VerGrupos,
  },
  data() {
    return {
      openAll: false,
      loading: false,
      btnIsLoading: false,
      userGroupId: 0,
      permisoSelected: [],
      tmpPermisosDTO: [],
      grupos: [],
      title: "Configuración de Permisos",
      flattenedArray: [],
      selectedBeforeUpdate: [],
      firstLoad: true,
      selected: [],
      checkedItems: [],
      routeToGo: "/usuarios",
      itemsPermisos: [],
      selectedItems: [],
      defaultErrorMsg: enums.messages.SYSTEM_ERROR,
      filterOption: null,
      openModalEditGrupos: false,
    };
  },
  async mounted() {
    this.loading = true;

    const grupos = await this.getGruposUsuario();
    this.grupos = grupos;
    this.userGroupId = this.grupos[0].userGroupId;
    const permisos = await this.permisosByGroup(this.userGroupId);

    this.itemsPermisos = permisos.optionsRoles[0].children;

    this.loading = false;
  },
  computed: {},
  methods: {
    ...mapActions({
      getGruposUsuario: "usuarios/getGruposUsuario",
      permisosByGroup: "usuarios/permisosByGroup",
      getOptions: "usuarios/getOptions",
      SavePermissions: "usuarios/SavePermissions",
      SaveFilters: "usuarios/SaveFilters",
      setAlert: "user/setAlert",
    }),
    async onGrupoChange(item) {
      this.loading = true;
      this.userGroupId = item;
      this.openAll = false;
      const permisos = await this.permisosByGroup(this.userGroupId);
      this.itemsPermisos = permisos.optionsRoles[0].children;

      this.loading = false;
    },

    async onItemChecked(item) {
      // si tiene children y tiene selected true => a todos sus children ponerle la propiedad selected en true

      if (item.children.length > 0 && item.selected == true) {
        // tiene hijos
        const hijos = item.children;
        hijos.forEach((element) => {
          element.selected = true;
          if (element.children.length > 0 && element.selected == true) {
            // tiene hijos
            const hijos1 = element.children;
            hijos1.forEach((elementchild) => {
              elementchild.selected = true;
            });
            element.children = hijos1;
          }
        });
        item.children = hijos;
      }
      if (item.children.length > 0 && item.selected == false) {
        // tiene hijos
        const hijos = item.children;
        hijos.forEach((element) => {
          element.selected = false;
          if (element.children.length > 0 && element.selected == false) {
            // tiene hijos
            const hijos1 = element.children;
            hijos1.forEach((elementchild) => {
              elementchild.selected = false;
            });
            element.children = hijos1;
          }
        });
        item.children = hijos;
      }
      if (item.children.length == 0) {
        this.padre = item.parentId;
        this.recursiveMap(this.itemsPermisos);
      }
    },

    async save() {
      const permisos = await this.permisosByGroup(this.userGroupId);
      const incidentes = permisos.optionsRoles[0];
      incidentes.selected = true;
      this.selectedItems = this.itemsPermisos.filter((item) => item.selected);

      let payload = [
        {
          sysgId: this.userGroupId,
          optionId: incidentes.optionId,
        },
      ];

      const processItem = (item) => {
        if (item.selected) {
          payload.push({
            sysgId: this.userGroupId,
            optionId: item.optionId,
          });
          this.SaveFilters({
            filters: "",
            opcionId: item.optionId,
          });
        }

        if (item.children && item.children.length > 0) {
          item.children.forEach((child) => {
            processItem(child);
          });
        }
      };

      this.itemsPermisos.forEach((item) => {
        processItem(item);
      });

      try {
        this.btnIsLoading = true;
        const res = await this.SavePermissions(payload);
        if (res.status == 200) {
          this.setAlert({ type: "success", message: "Actualizado con éxito" });
        }
      } catch (error) {
        this.setAlert({ type: "error", message: this.defaultErrorMsg });
        this.btnIsLoading = false;
      }

      this.btnIsLoading = false;
      payload = [];
    },

    recursiveMap(items) {
      items.forEach((item) => {
        // Imprime el elemento actual
        if (this.padre == item.optionId) {
          item.selected = true;
        }
        // Si el elemento tiene hijos, llamamos recursivamente a la función
        if (item.children && item.children.length > 0) {
          this.recursiveMap(item.children);
        }
      });
    },
    openModalGrupos() {
      this.openModalEditGrupos = true;
    },
    async closeModalGrupos() {
      this.openModalEditGrupos = false;
      const grupos = await this.getGruposUsuario();
      this.grupos = grupos;
      this.userGroupId = this.grupos[0].userGroupId;
      const permisos = await this.permisosByGroup(this.userGroupId);
      this.itemsPermisos = permisos.optionsRoles[0].children;
    },
  },
};
</script>
<style scoped>
.rotate {
  transform: rotate(-180deg);
}
</style>
