<template>
  <v-card>
    <v-container>
      <v-card-title class="p-0">
        <span class="primary--text">{{ title }}</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="grupos"
        class="elevation-1"
        :search="search"
        :loading="isLoading">
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details></v-text-field>
              </v-col>
              <v-col cols="4" align="end">
                <v-btn color="primary" @click="openModal()" class="to-right">
                  Nuevo grupo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item)">
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar grupo</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="removeGrupo(item.userGroupId)">
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar grupo</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="openModalEdit"
        v-model="openModalEdit"
        max-width="400px"
        @keydown.esc="closeModal"
        persistent>
        <EditGrupo
          :GrupoObj="GrupoObj"
          @closeAndReload="closeAndReload"></EditGrupo>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'25%'"
        :isLoading="loadingDeleteBtn"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()" />
    </v-container>
  </v-card>
</template>
<script>
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import EditGrupo from "./EditGrupo.vue";

export default {
  name: "VerGrupos",
  components: {
    DeleteDialog,
    EditGrupo,
  },
  data() {
    return {
      rules: rules,
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      title: "Grupos",
      titleDelete: "¿Eliminar grupo?",
      grupos: [],
      GrupoObj: {},
      search: "",
      loadingDeleteBtn: false,
      openModalEdit: false,
      showDeleteModal: false,
      isLoading: false,
      headers: [
        {
          text: "Codigo de grupo",
          value: "userGroupId",
          descripcion: "",
          sortable: false,
        },
        {
          text: "Nombre del grupo",
          value: "userGroupNom",
          descripcion: "",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "actions",
          descripcion: "",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.getAgrupacionesEntidades();
  },
  methods: {
    ...mapActions({
      getGruposUsuario: "usuarios/getGruposUsuario",
      deleteGrupoUsuario: "usuarios/deleteGrupoUsuario",
      setAlert: "user/setAlert",
    }),
    async getAgrupacionesEntidades() {
      this.isLoading = true;
      const response = await this.getGruposUsuario();
      this.grupos = response;
      this.isLoading = false;
    },
    openModal(item) {
      this.openModalEdit = true;
      this.GrupoObj = item;
    },
    closeModal() {
      // this.openModalEdit = false;
      this.$emit("closeModalAgrupacion");
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.getAgrupacionesEntidades();
    },
    removeGrupo(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      try {
        const response = await this.deleteGrupoUsuario(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.getAgrupacionesEntidades();
          this.loadingDeleteBtn = false;
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadingDeleteBtn = false;
      }
    },
  },
};
</script>
